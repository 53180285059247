Mostra
<div ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-primary"
    id="limitDropdown"
    ngbDropdownToggle
  >
    {{ this.limit }}
  </button>
  <div ngbDropdownMenu aria-labelledby="paginationsSize">
    <button
      ngbDropdownItem
      *ngFor="let option of this.options"
      (click)="changeLimit(option)"
    >
      {{ option }}
    </button>
  </div>
</div>
risultati.
