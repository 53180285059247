import { Component, OnInit } from "@angular/core";
import { SpinnerOverlayService } from "src/app/shared/services/spinner-overlay.service";

@Component({
  templateUrl: "./faq-utenti.page.html",
  styleUrls: ["./faq-utenti.page.scss"],
})
export class FaqUtentiPage implements OnInit {
  constructor(private spinner: SpinnerOverlayService) {}

  ngOnInit() {
    this.spinner.hide();
  }
}
