import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { PayerPaginatorComponent } from "./components/paginator/paginator.component";
import { PayerTableFooterComponent } from "./components/table-footer/table-footer.component";
import { PayerTableComponent } from "./components/table/table.component";
import { SortableColumnDirective } from './directives/sort-field/sortable-column.directive';
import { PayerPageLimiterComponent } from "./components/page-limiter/page-limiter.component";

@NgModule({
  declarations: [	
    PayerTableComponent,
    PayerTableFooterComponent,
    PayerPaginatorComponent,
    PayerPageLimiterComponent,
    SortableColumnDirective
   ],
  imports: [
    CommonModule,
    NgbDropdownModule,
  ],
  exports: [
    PayerTableComponent,
    PayerPaginatorComponent,
    PayerPageLimiterComponent,
    SortableColumnDirective
  ]
})
export class PayerTableModule {}
