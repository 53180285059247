import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "payer-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PayerPaginatorComponent implements OnChanges {
  private readonly DEFAUL_PAGE_LIMIT: number = 5;

  @Input()
  page: number = 1;
  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  total: number = this.DEFAUL_PAGE_LIMIT;
  @Input()
  pageLimit: number = this.DEFAUL_PAGE_LIMIT;

  availablePages: number[];
  hasPagesLeft: boolean;
  hasPagesRight: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.page) {
      this.changePage(this.page);
    }
    if (changes.total) {
      this.changePage(Math.min(Math.max(1, this.page), this.total));
    }
  }

  changePage(page: number): void {
    this.page = page;
    this.pageChange.emit(page);

    const start = this.page - Math.floor(this.pageLimit / 2);
    this.availablePages = Array.from(
      { length: this.pageLimit },
      (_, i) => i + start
    );

    this.hasPagesLeft = this.availablePages[0] > 1;
    this.hasPagesRight = this.availablePages[this.pageLimit - 1] < this.total;
  }
}
