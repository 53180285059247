<div class="container">

  <div class="faq-header d-flex justify-content-center">
    <h1 class="w-75 text-center">
      Domande frequenti per operatori Ente di PayER
    </h1>
  </div>
  
  <hr />
  
  <h5>Come posso vedere i dettagli dei pagamenti?</h5>
  <p>
    &Egrave; possibile reperire i dettagli dei pagamenti incassati tramite API,
    tramite cruscotto o riceverli tramite mail. Per dettagli scrivi a
    <a href="mailto:piattaformeenti@lepida.it">
      piattaformeenti&#64;lepida.it.
    </a>
  </p>
  
  <h5>Come posso abilitare una nuova utenza al cruscotto di monitoraggio?</h5>
  <p>
    Sarà sufficiente compilare il
    <a href="https://integrazioni.lepida.it/riservata/form/richiesta-utenze-payer"
    >form richiesta utenza</a
    >.
  </p>
  
  <h5>
    Come posso disattivare un'utenza abilitata al cruscotto di monitoraggio?
  </h5>
  <p>
    Sarà sufficiente compilare il
    <a href="https://integrazioni.lepida.it/riservata/form/richiesta-utenze-payer"
    >form richiesta utenza</a
    >.
  </p>
  
  <h5>Come posso chiedere l'attivazione di un nuovo servizio?</h5>
  <p>
    Puoi chiedere l'attivazione di un nuovo servizio compilando il
    <a
    href="https://integrazioni.lepida.it/riservata/form/payer-nuova-integrazione"
    >form nuova integrazione</a
    >.
  </p>
  
  <h5>Come posso chiedere la disattivazione di un servizio?</h5>
  <p>
    Puoi chiedere la diasattivazione di un servizio compilando il
    <a
    href="https://integrazioni.lepida.it/riservata/form/disattivazione-integrazione-payer"
    >form di disattivazione</a
    >.
  </p>
  
  <h5>Come posso chiedere la modifica di un servizio esistente?</h5>
  <p>
    Puoi chiedere la modifica di un servizio compilando il
    <a
    href="https://integrazioni.lepida.it/riservata/form/modifica-integrazione-payer"
    >form di modifica integrazione</a
    >.
  </p>
  
  <h5>Dove posso trovare la documentazione relativa a PayER?</h5>
  <p>
    Le specifiche tecniche generiche sono accessibili dal seguente
    <a
    href="https://drive.google.com/drive/folders/1Hh2nGDcbGwzzga9ad97UPZUpmf7FKElp"
    >link</a
    >.
  </p>
  <p>
    La documentazione relativa a Modello 3 è fruibile dal seguente
    <a href="http://apipayerdoc.lepida.it/">link</a>.<br />
    Per accedere usare le credenziali:
  </p>
  <ul>
    <li>username: anonimo</li>
    <li>password: anonimo</li>
  </ul>
  
  <h5>Il responsabile del servizio è cambiato, a chi posso comunicarlo?</h5>
  <p>
    Scrivi a
    <a href="mailto:piattaformeenti@lepida.it">
      piattaformeenti&#64;lepida.it.
    </a>
    indicando Ente, servizio, nome, telefono e recapito mail del nuovo referente.
    Vi invitiamo ad aggiornare sempre i referenti o a indicare come mail un box
    condiviso in modo da restare sempre aggiornati su eventuali aggiornamenti o
    attività di manutenzione.
  </p>
  
  <h5>
    La tesoreria ha bisogno che i flussi dei PSP siano diversi per ogni servizio,
    come posso fare?
  </h5>
  <p>
    Nel caso abbiate questa esigenza vi invitiamo a usare un conto corrente
    dedicato per ogni servizio.
  </p>
  
  <h5>
    Un pagamento è stato effettuato pochi giorni fa, ma non è ancora disponibile
    il flusso di quadratura del PSP, come mai?
  </h5>
  <p>
    I flussi di quadratura vengono generalmente inviati nella prima data contabile
    disponibile successiva al pagamento, tuttavia possono subire dei ritardi.
    Generalmente un ritardo fino a 5 giorni viene ritenuto normale. Vi invitiamo
    ad aprire un ticket ad assistenza solo per ritardi superiori ai 15 giorni o in
    caso di urgenza.
  </p>
  
  <h5>
    Ho dei pagamenti il cui IUV inizia per 47, ma non riesco a ricondurli a nessun
    servizio di pagamento. Di cosa si tratta?
  </h5>
  <p>
    I pagamenti il cui IUV inizia per 47 sono relativi a Canone Unico Patrimoniale
    Corporate. Tali pagamenti sono gestiti direttamente da pagoPA ed è possibile
    vederne il dettaglio sul sito
    <a href="https://selfcare.pagopa.it/">Selfcare PagoPA</a> nella sezione
    denominata CUP.
  </p>
  
  <h5>
    Come posso verificare o modificare l'IBAN su cui mi viene addebitato il Canone
    Unico Patrimoniale Corporate?
  </h5>
  <p>
    Tale informazione è riportata e modificabile sul sito
    <a href="https://selfcare.pagopa.it/">Selfcare PagoPA</a>, nella sezione
    relativa all'IBAN. I pagamenti Canone Unico Patrimoniale Corporate sono
    infatti gestiti direttamente da pagoPA.
  </p>
  
</div>