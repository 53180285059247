<table>
  <!-- Colgroup -->
  <colgroup>
    <col *ngIf="this.type === 'selectable'" class="select-col" />
  </colgroup>

  <!-- Headers -->
  <thead>
    <tr *ngIf="this.displayData">
      <th *ngIf="this.type === 'selectable'" class="align-middle">
        <input
          type="checkbox"
          (change)="onSelectAll($event.target.checked)"
          [checked]="this.selection.size == this.data.length"
          [indeterminate]="
            this.selection.size > 0 && this.selection.size < this.data.length
          "
        />
      </th>
      <ng-container
        [ngTemplateOutlet]="headersTemplate || defaultHeaders"
        [ngTemplateOutletContext]="{ $implicit: this.displayData[0] }"
      ></ng-container>
    </tr>
  </thead>

  <!-- Rows -->
  <tbody>
    <ng-container *ngIf="this.displayData">
      <tr *ngFor="let d of this.displayData">
        <td *ngIf="this.type === 'selectable'">
          <input
            type="checkbox"
            (change)="onSelectRow($event.target.checked, d)"
            [checked]="this.selection.has(d)"
          />
        </td>
        <ng-container
          [ngTemplateOutlet]="rowsTemplate || defaultRows"
          [ngTemplateOutletContext]="{ $implicit: d }"
        ></ng-container>
      </tr>
    </ng-container>
  </tbody>
</table>
<payer-table-footer
  [length]="this.data.length"
  (displayChange)="this.updateDisplay($event)"
></payer-table-footer>

<ng-template #defaultHeaders let-head>
  <th *ngFor="let kv of head | keyvalue" [sortOn]="kv.key">
    {{ kv.key + " fffffffffffff" }}
  </th>
</ng-template>

<ng-template #defaultRows let-row>
  <td *ngFor="let kv of row | keyvalue">
    {{ kv.value }}
  </td>
</ng-template>
