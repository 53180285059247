import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { environment } from "src/environments/environment";
import { ErrorePage } from "./core/errors/pagina-errore/errore.page";
import { CookiePolicyPage } from "./core/pages/cookie-policy/cookie-policy.page";
import { PrivacyPage } from "./core/pages/privacy/privacy.page";
import { CarrelloComponent } from "./features/pagamenti/raw/carrello/carrello.component";
import { CarrelloL1Component } from "./features/pagamenti/raw/carrelloL1/carrello-l1.component";
import { IMieiPagamentiComponent } from "./features/pagamenti/raw/i-miei-pagamenti/i-miei-pagamenti.component";
import { NuovoPagamentoComponent } from "./features/pagamenti/raw/nuovo-pagamento/nuovo-pagamento.component";
import { PresaincaricopagamentoComponent } from "./features/pagamenti/raw/presaincaricopagamento/presaincaricopagamento.component";
import { PresaincaricopagamentoL1Component } from "./features/pagamenti/raw/presaincaricopagamentoL1/presaincaricopagamento-l1.component";
import { WaitingComponent } from "./features/pagamenti/raw/waiting/waiting.component";
import { WaitingL1Component } from "./features/pagamenti/raw/waitingL1/waiting-l1.component";
import { AuthguardService } from "./services-old/authguard.service";
import { AmministratoreAuthGuard } from "./shared/common/authguards/amministratore.authguard";
import { BreadcrumbInfo } from "./shared/common/components/breadcrumb/breadcrumb.component";
import { TableTesterPage } from "./utility/pages/table-tester/table-tester.page";
import { TesterPage } from "./utility/pages/tester/tester.page";
import { UILibraryPage } from "./utility/pages/ui-library/ui-library.page";
import { HomePage } from "./core/pages/home/home.page";
import { FaqUtentiPage } from "./features/info/faq-utenti/faq-utenti.page";
import { FaqEntiPage } from "./features/info/faq-enti/faq-enti.page";

const prodRoutes: Routes = [
  // Pagina Home -----------------------------------------------------------
  { path: "home", component: HomePage },
  { path: "", redirectTo: "/home", pathMatch: "full" },

  // Pagine di errore ------------------------------------------------------
  {
    path: "badrequest",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "400",
        message: "Bad request",
      },
    },
  },
  {
    path: "nonautorizzato",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "401",
        message: "Operazione non permessa",
      },
    },
  },
  {
    path: "notfound",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "404",
        message: "Risorsa non trovata",
      },
    },
  },
  {
    path: "erroregenerico",
    component: ErrorePage,
    data: {
      errorinfo: {
        code: "500",
        message: "Errore di sistema",
      },
    },
  },

  // Pagamenti -------------------------------------------------------------
  {
    path: "nuovoPagamento",
    component: NuovoPagamentoComponent,
    canActivate: [AuthguardService],
  },
  {
    path: "nuovoPagamento/:idServizioDiretto",
    component: NuovoPagamentoComponent,
  },
  { path: "iMieiPagamenti", component: IMieiPagamentiComponent },
  { path: "carrello", component: CarrelloComponent },
  { path: "carrelloL1", component: CarrelloL1Component },
  {
    path: "presaincaricopagamento",
    component: PresaincaricopagamentoComponent,
  },
  {
    path: "presaincaricopagamentoL1",
    component: PresaincaricopagamentoL1Component,
  },
  { path: "waiting", component: WaitingComponent },
  { path: "waitingL1", component: WaitingL1Component },

  // Info
  { path: "faq", component: FaqUtentiPage },
  {
    path: "faq-enti",
    component: FaqEntiPage,
    canActivate: [AmministratoreAuthGuard],
  },

  // Pagine Footer ---------------------------------------------------------
  { path: "privacy", component: PrivacyPage },
  { path: "cookie-policy", component: CookiePolicyPage },

  // Amministra Portale -----------------------------------------------------
  {
    path: "",
    data: {
      breadcrumb: <BreadcrumbInfo>{
        label: "Amministra Portale",
      },
    },
    canActivateChild: [AmministratoreAuthGuard],
    children: [
      { path: "", redirectTo: "/home", pathMatch: "full" },
      {
        path: "gestisciUtenti",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Gestisci Utenti",
          },
        },
        loadChildren: () =>
          import(
            "./features/amministra-portale/gestisci-utenti/gestisci-utenti.module"
          ).then((m) => m.GestisciUtentiModule),
      },
      {
        path: "monitoraAccessi",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Monitora Accessi",
          },
        },
        loadChildren: () =>
          import(
            "./features/amministra-portale/monitora-accessi/monitora-accessi.module"
          ).then((m) => m.MonitoraAccessiModule),
      },
      {
        path: "gestisciBanner",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Gestisci Banner",
          },
        },
        loadChildren: () =>
          import(
            "./features/amministra-portale/gestisci-banner/gestisci-banner.module"
          ).then((m) => m.GestisciBannerModule),
      },
      {
        path: "configuraServizi",
        loadChildren: () =>
          import(
            "./features/amministra-portale/configura-servizi/configura-servizi.module"
          ).then((m) => m.ConfiguraServiziModule),
      },
      {
        path: "gestisciStatistiche",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Gestisci Statistiche",
          },
        },
        loadChildren: () =>
          import(
            "./features/amministra-portale/gestisci-statistiche/gestisci-statistiche.module"
          ).then((m) => m.GestisciStatisticheModule),
      },
      {
        path: "gestisciAttivitaPianificate",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Gestisci Attività Pianificate",
          },
        },
        loadChildren: () =>
          import(
            "./features/amministra-portale/gestisci-attivita-pianificate/gestisci-attivita-pianificate.module"
          ).then((m) => m.GestisciAttivitaPianificateModule),
      },
      {
        path: "configuraPortaliEsterni",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Configura Portali Esterni",
          },
        },
        loadChildren: () =>
          import(
            "./features/amministra-portale/configura-portali-esterni/configura-portali-esterni.module"
          ).then((m) => m.ConfiguraPortaliEsterniModule),
      },

      // Gestisci Anagrafiche ----------------------------------------------
      {
        path: "",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Gestisci Anagrafiche",
          },
        },
        children: [
          { path: "", redirectTo: "/home", pathMatch: "full" },
          {
            path: "gestisciSocieta",
            data: {
              breadcrumb: <BreadcrumbInfo>{
                label: "Gestisci Società",
              },
            },
            loadChildren: () =>
              import(
                "./features/gestisci-anagrafiche/gestisci-societa/gestisci-societa.module"
              ).then((m) => m.GestisciSocietaModule),
          },
          {
            path: "gestisciLivelliTerritoriali",
            data: {
              breadcrumb: <BreadcrumbInfo>{
                label: "Gestisci Livelli Territoriali",
              },
            },
            loadChildren: () =>
              import(
                "./features/gestisci-anagrafiche/gestisci-livelli-territoriali/gestisci-livelli-territoriali.module"
              ).then((m) => m.GestisciLivelliTerritorialiModule),
          },
          {
            path: "gestisciEnti",
            data: {
              breadcrumb: <BreadcrumbInfo>{
                label: "Gestisci Enti",
              },
            },
            loadChildren: () =>
              import(
                "./features/gestisci-anagrafiche/gestisci-enti/gestisci-enti.module"
              ).then((m) => m.GestisciEntiModule),
          },
          {
            path: "gestisciTipologiaServizi",
            data: {
              breadcrumb: <BreadcrumbInfo>{
                label: "Gestisci Tipologia Servizi",
              },
            },
            loadChildren: () =>
              import(
                "./features/gestisci-anagrafiche/gestisci-tipologia-servizio/gestisci-tipologia-servizio.module"
              ).then((m) => m.GestisciTipologiaServizioModule),
          },
          {
            path: "raggruppamentoTipologie",
            data: {
              breadcrumb: <BreadcrumbInfo>{
                label: "Raggruppamento Tipologie",
              },
            },
            loadChildren: () =>
              import(
                "./features/gestisci-anagrafiche/raggruppamento-tipologie/raggruppamento-tipologie.module"
              ).then((m) => m.RaggruppamentoTipologieModule),
          },
          // {
          //   path: "gestisciFlussiRendicontazione",
          //   data: {
          //     breadcrumb: <BreadcrumbInfo>{
          //       label: "Gestisci Flussi Rendicontazione",
          //     },
          //   },
          //   loadChildren: () =>
          //     import(
          //       "./features/gestisci-anagrafiche/gestisci-flussi-rendicontazione/gestisci-flussi-rendicontazione.module"
          //     ).then((m) => m.GestisciFlussiRendicontazioneModule),
          // }
        ],
      },
    ],
  },

  // Gestisci Portale -----------------------------------------------------
  {
    path: "",
    data: {
      breadcrumb: <BreadcrumbInfo>{
        label: "Gestisci Portale",
      },
    },
    canActivateChild: [AmministratoreAuthGuard],
    children: [
      { path: "", redirectTo: "/home", pathMatch: "full" },
      {
        path: "monitoraggioTransazioni",
        loadChildren: () =>
          import(
            "./features/gestisci-portale/monitoraggio-transazioni/monitoraggio-transazioni.module"
          ).then((m) => m.MonitoraggioTransazioniModule),
      },
      {
        path: "rendicontazione",
        loadChildren: () =>
          import(
            "./features/gestisci-portale/rendicontazione/rendicontazione.module"
          ).then((m) => m.RendicontazioneModule),
      },
      {
        path: "quadratura",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Quadratura",
          },
        },
        loadChildren: () =>
          import(
            "./features/gestisci-portale/quadratura/quadratura.module"
          ).then((m) => m.QuadraturaModule),
      },
      {
        path: "iuvSenzaBonifico",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "IUV senza bonifico",
          },
        },
        loadChildren: () =>
          import(
            "./features/gestisci-portale/iuv-senza-bonifico/iuv-senza-bonifico.module"
          ).then((m) => m.IuvSenzaBonificoModule),
      },
      {
        path: "scaricaRicevuta",
        data: {
          breadcrumb: <BreadcrumbInfo>{
            label: "Scarica Ricevuta",
          },
        },
        loadChildren: () =>
          import(
            "./features/gestisci-portale/scarica-ricevuta/scarica-ricevutra.module"
          ).then((m) => m.ScaricaRicevutaModule),
      },
    ],
  },

  // Wildcard redirect
  { path: "**", redirectTo: "/notfound" },
];

const stageRoutes: Routes = [
  {
    path: "tester",
    component: TesterPage,
  },
  {
    path: "ui-library",
    component: UILibraryPage,
  },
  {
    path: "table-tester",
    component: TableTesterPage,
  },
];

const options: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  // enableTracing: true
};

const routes: Routes = environment.production
  ? prodRoutes
  : stageRoutes.concat(prodRoutes);

@NgModule({
  imports: [RouterModule.forRoot(routes, options)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
