<button (click)="this.changePage(1)" [disabled]="this.page == 1">
  <svg class="icon icon-primary">
    <use
      href="assets/icons/bootstrap-italia-icons-expanded.svg#it-double-chevron-left"
    ></use>
  </svg>
</button>
<button
  class="mr-3"
  (click)="this.changePage(this.page - 1)"
  [disabled]="this.page == 1"
>
  <svg class="icon icon-primary">
    <use href="assets/icons/bootstrap-italia-icons.svg#it-chevron-left"></use>
  </svg>
</button>

<div class="placeholder">
  <span *ngIf="this.hasPagesLeft">...</span>
</div>

<!-- Selettori pagine -->
<ng-container *ngFor="let p of this.availablePages">
  <button
    *ngIf="p >= 1 && p <= this.total"
    [ngClass]="{ selected: this.page == p }"
    (click)="this.changePage(p)"
  >
    {{ p }}
  </button>
  <div class="placeholder" *ngIf="p < 1 || p > this.total"></div>
</ng-container>

<div class="placeholder">
  <span *ngIf="this.hasPagesRight">...</span>
</div>

<button
  class="ml-3"
  (click)="this.changePage(this.page + 1)"
  [disabled]="this.page == total"
>
  <svg class="icon icon-primary">
    <use href="assets/icons/bootstrap-italia-icons.svg#it-chevron-right"></use>
  </svg>
</button>
<button (click)="this.changePage(this.total)" [disabled]="this.page == total">
  <svg class="icon icon-primary">
    <use
      href="assets/icons/bootstrap-italia-icons-expanded.svg#it-double-chevron-right"
    ></use>
  </svg>
</button>
