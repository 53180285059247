<div class="container">

  <div class="faq-header d-flex justify-content-center">
    <h1>Domande frequenti per utenti PayER</h1>
  </div>
  
  <hr>
  
  <h5>Quali sono i costi delle commissioni sui pagamenti?</h5>
  <p>
    I costi di commissione pagoPA sono consultabili alla pagina
    <a href="https://www.pagopa.gov.it/it/cittadini/trasparenza-costi/"
    >trasparenza costi</a
    >
    di PagoPA. Nel caso paghiate da homebanking i costi dipendono dal tipo di
    contratto in essere con la vostra banca.
  </p>
  
  <h5>
    Quando effettuo un pagamento mi viene sempre suggerito il costo di commissione
    inferiore?
  </h5>
  <p>
    No, vi invitiamo a verificare sempre se è presente un Prestatore di Servizi di
    Pagamento che offre una commissione inferiore.
  </p>
  
  <h5>Come posso essere sicuro che il pagamento è andato a buon fine?</h5>
  <p>
    Se hai pagato fisicamente presso uno sportello ed hai la ricevuta allora il
    pagamento è andato a buon fine. Se hai pagato online indicando il tuo
    indirizzo e-mail, potrai essere sicuro che il pagamento è andato a buon fine
    dopo aver ricevuto da pagoPA una mail di conferma del pagamento. La mail
    potrebbe non essere immediata, in caso di dubbio ti invitiamo ad attendere
    qualche ora prima di riprovare il pagamento.
  </p>
  
  <h5>Posso usare la ricevuta di Lepida per fini fiscali?</h5>
  <p>
    No, la ricevuta di Lepida serve per riepilogare i dati nel pagamento e può
    esserti utile se devi dimostrare all’Ente di aver pagato o chiedere una
    verifica sul pagamento stesso. La ricevuta per fini fiscali deve essere
    rilasciata dall’Ente creditore.
  </p>
  
  <h5>
    Perchè nell’elenco degli Enti/servizi su
    <a href="https://payer.lepida.net/">PayER</a> non trovo quello di mio
    interesse?
  </h5>
  <p>
    E’ possibile che l’Ente o il servizio da te ricercato non sia intermediato da
    Lepida. Chiedi informazioni all’Ente o apri una segnalazione ad
    <a href="http://www.lepida.net/assistenza">assistenza Lepida</a>.
  </p>
  
  <h5>
    Nella sezione “I miei pagamenti”, accessibile tramite SPID, trovo l’elenco di
    tutti i miei pagamenti pagoPA?
  </h5>
  <p>
    Nella sezione “I miei pagamenti” troverai il dettaglio dei pagamenti
    effettuati o da effettuare relativi unicamente ai servizi intermediati da
    Lepida.
  </p>
  
  <h5>
    Ho un avviso di pagamento cartaceo, posso pagarlo su
    <a href="https://payer.lepida.net/">PayER</a>?
  </h5>
  <p>
    No, ma puoi usare l’apposita
    <a href="https://checkout.pagopa.it/">pagina di pagoPA</a>.
  </p>
  
</div>