import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "payer-page-limiter",
  templateUrl: "./page-limiter.component.html",
  styleUrls: ["./page-limiter.component.scss"],
})
export class PayerPageLimiterComponent implements OnInit {
  private readonly DEFAULT_OPTIONS: number[] = [5, 10, 20];

  @Input()
  limit: number = this.DEFAULT_OPTIONS[0];
  @Output()
  limitChange: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  options: number[] = this.DEFAULT_OPTIONS;

  constructor() {}

  ngOnInit() {}

  changeLimit(limit: number) {
    if (this.options.indexOf(limit) != -1) {
      this.limit = limit;
      this.limitChange.emit(limit);
    }
  }
}
