import { Directive, HostListener, Input } from "@angular/core";
import { PayerTableComponent } from "../../components/table/table.component";

@Directive({
  selector: "th[sortOn]",
})
export class SortableColumnDirective {
  @Input()
  sortOn: string;

  constructor(private table: PayerTableComponent) {}

  @HostListener("click")
  sort(): void {
    this.table.requestSort(this.sortOn);
  }
}
