import { SpinnerOverlayService } from "src/app/shared/services/spinner-overlay.service";
import { OverlayService } from "./../../../shared/services/overlay.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-faq-enti",
  templateUrl: "./faq-enti.page.html",
  styleUrls: ["./faq-enti.page.scss"],
})
export class FaqEntiPage implements OnInit {
  constructor(private spinner: SpinnerOverlayService) {}

  ngOnInit() {
    this.spinner.hide();
  }
}
